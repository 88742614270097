@import './variable.less';

blockquote, body, button, dd, dl, dt, form, h1, h2, h3, h4, h5, h6, hr, input, legend, li, ol, p, pre, td, textarea, th, ul {
    padding: 0;
    margin: 0;
}
a {
    color: @textColor;
    text-decoration: none;
}

img {
    vertical-align: top;
}

ul, li {
    list-style: none;
}