:root {
  --primary-color: #ff385c;
}
blockquote,
body,
button,
dd,
dl,
dt,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
input,
legend,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
  padding: 0;
  margin: 0;
}
a {
  color: #484848;
  text-decoration: none;
}
img {
  vertical-align: top;
}
ul,
li {
  list-style: none;
}
body {
  font-size: 14px;
  font-family: "Circular", "PingFang-SC", 'Hiragino Sans GB', '微软雅黑', 'Microsoft YaHei', 'Heiti SC';
  color: #484848;
}
